/* global styles */
@import '~antd/dist/antd.css';

.App {
  font-family: 'Inter';
}

.container {
  display: flex;
}

.center {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 50px);
}
