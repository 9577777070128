.nav {
  padding: 10px 20px 10px 20px;
  background-color: #001c38;
  height: 50px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.navLeft,
.navRight {
  display: inline-flex;
  align-items: center;
  flex: 1;
}

.navLeft {
  white-space: nowrap;
  /* dont wrap Pace Revenue on mobile */
}

.navRight {
  justify-content: flex-end;
  align-items: center;
}

.navCenter {
  background-color: rgba(255, 255, 255, 0.2);
  display: none;
  border: 0px;
  border-radius: 50px;
  vertical-align: bottom;
  justify-content: center;
}

.navItem {
  display: inline-flex;
  border-radius: 50px;
  padding: 5px 15px 5px 15px;
  align-items: center;
  font-weight: 500;
  vertical-align: bottom;
  opacity: 0.9;
  transition: 0.1s;
}

.navItem.active {
  background-color: rgba(255, 255, 255, 1);
  color: #1d1d1f;
}

.navItem:not(.active) {
  color: rgba(255, 255, 255, 0.5);;
}

.navItem:not(.active):hover {
  color: white;
}

.navItemRight {
  display: inline-flex;
  align-items: center;
  opacity: 0.9;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 100%;
  padding: 5px;
  margin-left: 20px;
}

.navItemRight:hover {
  background-color: white;
  color: black;
}

.logo {
  height: 25px;
  width: auto;
}

@media (min-width: 1024px) {
  /* show navCenter on bigger screens */
  .navCenter {
    display: flex;
  }
  .nav {
    padding: 10px 50px 10px 50px;
  }
}
