.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: rgb(239, 239, 239);
  height: calc(100vh - 50px);
  color: #1d1d1f;
  padding: 0px 20px 0px 20px; 
}

.card {
  max-width: 500px;
  margin: auto;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-self: center
}

.title {
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 0px;
  color: #1d1d1f;
}

.subtitle {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0px;
  color: #4b5563;
}

.form {
  margin-top: 20px;
  background-color: #fff;
  border-color: #d2d6dc;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  padding-top: .5rem;
  padding-right: .75rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  font-size: 1rem;
}

.form:focus {
  border-color:#d2d6dc;
  outline: 0;
}

.button {
  margin-top: 20px;
  border-radius: 999px;
  padding: 0.75rem 2rem 0.75rem 2rem;
  border-width: 2px;
  font-size: 18px;
  font-weight: 600;
  background-color: white;
}

.button:hover {
  background-color: #1d1d1f;
  color: white;
  border-color: #1d1d1f;
}

button:focus {
  outline: 0;
}
